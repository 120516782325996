module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#27ad7a","theme_color":"#27ad7a","display":"minimal-ui","icon":"src/images/synetech-logo-white.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d31339ad250b254054ed70c44abc26a1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TSSWNBW","includeInDevelopment":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":["gatsby-remark-unwrap-images",{"resolve":"gatsby-remark-relative-images"},{"resolve":"gatsby-remark-images","options":{"maxWidth":800,"backgroundColor":"transparent","linkImagesToOriginal":false,"showCaptions":["title"],"tracedSVG":true}},{"resolve":"gatsby-remark-embed-gist","options":{"includeDefaultCss":true}},"gatsby-plugin-sharp",{"resolve":"gatsby-remark-responsive-iframe","options":{"wrapperStyle":"margin-bottom: 2em"}},"gatsby-remark-embedder","gatsby-remark-prismjs","gatsby-remark-copy-linked-files","gatsby-remark-smartypants",{"resolve":"gatsby-remark-emojis","options":{"active":true,"class":"emoji-icon","size":64,"styles":{"display":"inline","margin":"0","margin-top":"1px","position":"relative","top":"5px","width":"25px"}}},{"resolve":"gatsby-remark-autolink-headers","options":{"removeAccents":true,"icon":false}},{"resolve":"/builds/synetech/web/plugins/gatsby-remark-blog-index/index.js"}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/builds/synetech/web"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"enableIdentityWidget":false,"modulePath":"/builds/synetech/web/src/cms/cms.js","manualInit":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://5f47b0b7435147699baa26877120a2b1@sentry.synetech.cz/3","enabled":true,"environment":"0886afb7a0b274af5ad4a4c0488dd55ff0d8bed1"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
